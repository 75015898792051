require('./bootstrap');

$(document).ready(() => {
    const width = $(window).width();
    const carousels = $(".owl-carousel");
    const numberFormatRounded = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 });
    const numberFormat = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

    carousels.each((index, carousel) => {
        if (carousel.id === "owl-itens" && width <= 576) {
            $(carousel).owlCarousel({
                dots: true,
                responsive: {
                    0: {
                        items: 2,
                    },
                    576: {
                        items: 3,
                    },
                    1000: {
                        items: 5,
                    },
                    1200: {
                        items: 6,
                    },
                    1400: {
                        items: 7,
                    },
                }
            });
        } else if ($(carousel).hasClass('owl-plans')) {
            $(carousel).owlCarousel({
                loop: false,
                margin: 20,
                dots: false,
                nav: true,
                stagePadding: 50,
                autoHeight: false,
                autoHeightClass: 'owl-height',
                responsive: {
                    0: {
                        items: 1,
                    },
                    600: {
                        items: 2,
                    },
                    900: {
                        items: 3,
                    },
                    1200: {
                        items: 4,
                    },
                },
            });
        } else if (carousel.id === "owl-cases") {
            $(carousel).owlCarousel({
                loop: true,
                dots: true,
                items: 1,
                nav: false,
            });
        } else if (carousel.id === "owl-crm") {
            $(carousel).owlCarousel({
                items: 1,
                nav: true,
            });

            $(carousel).find("span").text('');
        }
    });


    $('input.type-plan-checkbox[name="type"]').on('change', function () {
        const active = $(this).val();

        $('.owl-plans').each((i, e) => {
            const input = $(e).parent().find('input[name="plans"]');
            const registerInput = $(e).parent().find('input[name="register"]');

            if (!input || !registerInput)
                return;

            const plans = JSON.parse(input.val());
            const registerUrl = registerInput.val();

            if (!(active in plans))
                return;

            const options = plans[active];
            let html = "";

            for (let i = 0; i < options.length; i++) {
                const plan = options[i];

                let economyBadge = "";
                let list = "";

                if (plan.original > plan.value) {
                    economyBadge += `
                        <span class="d-flex align-items-center preco-badge">
                            <s class="me-1">R$${numberFormat.format(plan.original)}</s>
                            <span class="badge badge-primary">
                                Economia ${numberFormatRounded.format(plan.discount)}%
                            </span>
                        </span>
                    `
                }

                if ('max_agents' in plan && plan.max_agents)
                    list += `<li>${numberFormatRounded.format(plan.max_agents)} ${plan.max_agents > 1 ? 'agentes' : 'agente'}</li>`

                if ('max_phones' in plan && plan.max_phones)
                    list += `<li>${numberFormatRounded.format(plan.max_phones)} ${plan.max_phones > 1 ? 'números' : 'número'} de disparo</li>`

                if ('max_customers' in plan && plan.max_customers)
                    list += `<li>Até ${numberFormatRounded.format(plan.max_customers)} ${plan.max_customers > 1 ? 'contatos' : 'contato'}</li>`
                else
                    list += `<li>Sem limite de contatos</li>`;

                const canShot = 'can_shot' in plan ? plan.can_shot : true;

                if (canShot && 'max_shots' in plan && plan.max_shots)
                    list += `<li>Até ${numberFormatRounded.format(plan.max_shots)} ${plan.max_shots > 1 ? 'disparos' : 'disparo'} por mês</li>`
                else if (canShot)
                    list += `<li>Sem limite de disparos</li>`;
                else
                    list += `<li>Apenas atendimento</li>`;

                html += `
                    <div class="plan-item item h-100 py-2 owl-height">
                        <div class="plano d-flex flex-column">
                            <p class="plano-nome text-center">
                                ${plan.name}
                            </p>

                            <p class="preco d-flex flex-column align-items-center">
                                ${economyBadge}

                                <span class="d-flex align-items-end mt-1">
                                    <small class="preco-fix">R$</small>
                                    <span>${numberFormat.format(plan.value)}</span>
                                    <small class="preco-fix">/mês</small>
                                </span>
                            </p>

                            <ul class="col-12 col-lg-10 offset-lg-1 flex-grow-1">
                                ${list}
                            </ul>

                            <a class="small" href="${registerUrl}?plan_id=${plan.id}&type=${active}">
                                Contratar agora
                            </a>
                        </div>
                    </div>
                `;
            }

            $(e).trigger('replace.owl.carousel', html).trigger('refresh.owl.carousel');
        })
    });

    $('input.type-plan-checkbox[name="type"]:checked').trigger('change');
});
